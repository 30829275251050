#right_ans{
    background-color: blue;
    color: white;
}
#wrong_ans{
    background-color: red;
    color: white;
}

/* App.css */
.green_btn {
    background-color: rgb(20 175 40);
    border-radius: 0;
    color: white;
}
.main_div_app {
    width: 100%;
    margin-top: 20px;
}

.questions_div {
    width: 100%;
    border-radius: 5px;
}

.question {
    background: linear-gradient(100deg, rgb(238, 255, 0), white);
    color: black;
    border-radius: 5px;
    padding: 10px;
}

.user_options_div {
    width: 98%;
    display: flex;
    margin: 0 1%;
}

.user_choose {
    display: flex;
    padding: 5px;
}

.user_options_div div:nth-child(1) {
    width: 80%;
}

.user_options_div div:nth-child(2) {
    width: 20%;
}

.user_options_div span:nth-child(1) {
    width: 10px;
    height: 50px;
    padding-left: 3px;
    border: 2px solid #3e86f1;
}

.user_options_div span:nth-child(2) {
    width: 30px;
    height: 30px;
    text-align: center;
    border: 2px solid blue;
    border-left: 0;
    margin-top: 5px;
}

.user_options_div span:nth-child(3) {
    height: 45px;
    margin-top: 1px;
    padding-left: 3px;
}

.user_ans {
    background-color: #3e86f1;
    border: none;
    color: white;
    margin-top: -1px;
    border-radius: 0;
}

.result_div {
    width: 40%;
    margin: 20px 0 0 30%;
    background-color: #3e86f1;
    color: white;
    font-weight: 500;
    font-size: 22px;
    border-radius: 5px;
    letter-spacing: 2px;
}

.buttons_div {
    width: 100%;
    margin: 10px 0;
    display: flex;
}

.buttons_div button {
    width: 140px;
}

@media (max-width:500px) {
    .result_div {
        width: 96%;
        margin-left: 2%;
    }
}