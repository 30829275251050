/* Welcome.js File CSS */

.welcome_page {
    width: 95%;
    margin: 10px 2.5%;
}

/* .logo_img {
    width: 250px;
    height: 90px;
} */

.bulb_img {
    width: 250px;
    height: 250px;
}

.subh {
    font-size: 50%;
}

@media (max-width:500px) {
    .welcome_page {
        width: 95%;
        margin: 10px 2.5%;
    }
    .logo_img {
        width: 250px;
        height: 100px;
    }
    .bulb_img {
        width: 250px;
        height: 250px;
    }
}


@media (max-width:500px) {
    .welcome_page {
        width: 95%;
        margin: 10px 2.5%;
    }
    .logo_img {
        width: 250px;
        height: 100px;
    }
    .bulb_img {
        width: 250px;
        height: 250px;
    }
}