.navbar {
    background-color: #3e86f1;
    color: white;
}

.navbar-nav {
    margin-left: 20%;
}

@media (max-width:500px) {
    .navbar-nav {
        margin-left: 0%;
    }
}