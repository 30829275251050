.setup_quiz_div,
.verification_div {
    width: 50%;
    margin: 30px 25%;
    background-color: #0d75f4;
    border: 2px solid white;
    color: white;
    border-radius: 7px;
    padding: 15px;
}
.verification_div{
    display: none;
}

.setup_quiz_div input, select {
    margin: 5px 0;
}

@media (max-width:500px) {
    .setup_quiz_div,
    .verification_div {
        width: 95%;
        margin-left: 2.5%;
    }
}