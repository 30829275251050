.main_div_before {
  width: 100%;
  position: fixed;
  height: 100%;
  background-color: #0d75f4;
  color: white;
}

.error {
  color: #ffff01;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: large;
}

.all_btn {
  width: 100%;
  background-color: #116bf1;
  border: none;
  border-radius: 5px;
  margin: 10px 0;
  font-size: 20px;
  padding: 7px 0;
  color: white;
  box-shadow: 1px 1px 8px 3px #043681;
  text-align: center;
  text-decoration: none;
}

.btn_hover:hover {
  color: black;
  background-color: rgb(238, 255, 0);
  transition: background-color 1s ease;
}